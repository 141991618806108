import { TranslationService } from './../../services/translation-service';
import { Subscription } from 'aurelia-event-aggregator';
import { ServiceImpregnationTestService } from './../../services/service-impregnation-test-service';
import { DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, bindable } from 'aurelia-framework';
import { Prompt } from 'elements/prompt';
import { Models } from 'models/core';
import { DryingMethodService } from 'services/drying-method-service';
import { ErrorService } from 'services/error-service';
import { ImpregnationMethodService } from 'services/impregnation-method-service';
import { ImpregnationTypeService } from 'services/impregnation-type-service';
import { NetService } from 'services/net-service';
import { ServiceService } from 'services/service-service';
import { ToastService } from 'services/toast-service';
import { Utility } from 'utility';
import { I18N } from 'aurelia-i18n';
import { ParseIntValueConverter } from 'value-converters/parse-int';

@autoinject
export class ServiceDetailImpregnation {
  @bindable private serviceId: number;
  private net: Models.Net;
  private service: Models.Service;
  private customerId: number;
  private originalObject;
  private locked;
  private dryingTime = null;
  //Show additional specific fields if customer is MOWI Sør, Midt, Nord, Stamfisk: 
  private showMowiFields: boolean = false;
  private oneToFourOptions = [{Id: 1, Value: 1, Name: "1"}, {Id: 2, Value: 2, Name: "2"}, {Id: 3, Value: 3, Name: "3"}, {Id: 4, Value: 4, Name: "4"}]
  private nextTabIndex: number = null;
  private serviceImpregnationTests: Array<Models.ServiceImpregnationTest>;

  private testEditFormVisible = null;

  private resetServiceImpregnationTestListSubscription: Subscription;
  private resetServiceImpregnationListSubscription: Subscription;

  @bindable private netId;
  @bindable private isDialog: boolean = false;

  constructor(
    private dialogService: DialogService,
    private dryingMethodService: DryingMethodService,
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private impregnationMethodService: ImpregnationMethodService,
    private impregnationTypeService: ImpregnationTypeService,
    private impregnationTestService: ServiceImpregnationTestService,
    private netService: NetService,
    private prompt: Prompt,
    private serviceService: ServiceService,
    private toastService: ToastService,
    private i18n: I18N,
    private translationService: TranslationService,
    private utility: Utility,
    private parseIntValueConverter: ParseIntValueConverter
  ) {}

  private attached() {
    if (this.serviceId && this.netId) {
      this.activate({ Id: this.serviceId, NetId: this.netId });
    }
  }

  private activate(params) {
    this.serviceId = params.Id;

    this.getService(params.Id);
    this.getNet(params.NetId);
    this.getServiceImpregnationTest(params.Id);

    // Get new tabIndex for tabs component via EA, store value so we can publish this if canDeactivate returns true
    this.eventAggregator.subscribe('changeTab', (tabIndex) => {
      this.nextTabIndex = tabIndex;
    });

    this.resetServiceImpregnationTestListSubscription = this.eventAggregator.subscribe(
      'serviceImpregnationTestListReset',
      (value) => {
        this.getServiceImpregnationTest(this.serviceId);
        return;
      }
    );

    this.resetServiceImpregnationListSubscription = this.eventAggregator.subscribe(
      'serviceImpregnationListReset',
      (value) => {
        // get updated summed liters
        this.serviceService
          .get(this.service.Id)
          .then((updatedService) => {
            this.service.ImpregnationLitersSumAll = updatedService.ImpregnationLitersSumAll;
            this.service.ImpregnationLiters =
              (this.service.ImpregnationLitersSumAll || 0) - (this.service.ImpregnationLitersRemaining || 0);
          })
          .catch((err) => {
            // not very important if this fails, just ignore it
          });
      }
    );
  }

  private deactivate() {
    if (this.resetServiceImpregnationTestListSubscription) {
      this.resetServiceImpregnationTestListSubscription.dispose();
    }

    if (this.resetServiceImpregnationListSubscription) {
      this.resetServiceImpregnationListSubscription.dispose();
    }
  }

  private updateStatusAntifouledComplete() {
    this.serviceService
      .updateServiceStatus(5, this.service.Id)
      .then((res) => {
        if (!this.service.AntifouledDate) {
          this.service.AntifouledDate = res.AntifouledDate;
        }

        this.toastService.showSuccess('service.serviceStatusChanged');
        this.saveService();
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private changeRemainingAntifouling() {
    this.service.ImpregnationLiters =
      (this.service.ImpregnationLitersSumAll || 0) - (this.service.ImpregnationLitersRemaining || 0);
  }

  private saveService() {
    this.serviceService
      .put(this.service, this.service.Id)
      .then((res) => {
        this.toastService.showSuccess('service.impregnationSaved');
        this.eventAggregator.publish('serviceUpdated');
        this.updateOriginalObject();
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private getService(id) {
    this.serviceService
      .get(id)
      .then((res) => {
        this.service = res;
        this.locked = res.Locked ? res.Locked : false;
        this.dryingTime = res.ImpregnationHoursDrying ? res.ImpregnationHoursDrying * 60 : null;
        this.updateOriginalObject();
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private setDryingTime(event){
    this.dryingTime = event.target.value === "" ? null : this.parseIntValueConverter.fromView(event.target.value) * 60;
  }

  private getNet(id) {
    this.netService
      .get(id)
      .then((res) => {
        this.net = res;
        this.customerId = res.CustomerId;
        this.checkIfCustomerIsMowi(res.CustomerId);
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private checkIfCustomerIsMowi(customerId: number) {
    // MOWI Sør, Midt, Nord, Nord, Stamfisk: 
    const mowiCustomerIds = [236, 271, 149, 333, 228];
    this.showMowiFields = mowiCustomerIds.includes(customerId) ? true : false;
  }

  public canDeactivate() {
    if (
      this.originalObject &&
      (!this.utility.areEqual(this.service, this.originalObject) || !!this.testEditFormVisible)
    ) {
      return this.dialogService
        .open({
          viewModel: Prompt,
          model: {
            header: 'dialog.subFormOpenHeading',
            message: 'dialog.subFormOpenMessage',
          },
        })
        .whenClosed((response) => {
          if (response.wasCancelled) {
            return false;
          } else {
            const result = response.output;
            if (result === 'save') {
              // Save the service and let that function handle the rest of the logic
              this.saveService();
              return false;
            } else {
              this.eventAggregator.publish('changeTab-success', this.nextTabIndex);
              return true;
            }
          }
        });
    } else {
      this.eventAggregator.publish('changeTab-success', this.nextTabIndex);
      return true;
    }
  }

  private updateOriginalObject() {
    this.originalObject = JSON.parse(JSON.stringify(this.service));
  }

  private getServiceImpregnationTest(id) {
    this.impregnationTestService
      .getAll('?$filter=ServiceId eq ' + id + '&$expand=ImpregnationTestType&$orderby=Id')
      .then((res) => {
        this.serviceImpregnationTests = res;
      })
      .catch((err) => this.errorService.handleError(err));
  }
  protected async getImpregnationTypes() {
    const types = await this.impregnationTypeService.getAll(`?$expand=NavisionProduct`);
    if (this.service.IsInvoicingCompatible) {
      return types
        .filter((i) => i.NavisionProductId && !i.IsDeleted)
        .map((i) => {
          i.Name = i.NavisionProduct.ExternalName + ' (' + i.NavisionProduct.ArticleNo + ')';
          i.NameEn = i.NavisionProduct.ExternalName + ' (' + i.NavisionProduct.ArticleNo + ')';
          i.NameEs = i.NavisionProduct.ExternalName + ' (' + i.NavisionProduct.ArticleNo + ')';
          return i;
        });
    }

    types.forEach((type) => {
      if (type.NavisionProduct) {
        type.Name = type.Name + ' (' + type.NavisionProduct.ArticleNo + ')';
        type.NameEn = type.NameEn + ' (' + type.NavisionProduct.ArticleNo + ')';
        type.NameEs = type.NameEs + ' (' + type.NavisionProduct.ArticleNo + ')';
      }
    });

    return types;
  }

  private editLabel(test) {
    let label = this.i18n.tr('general.edit') + ' ';

    label += this.translationService.getLocalizedValue(test.ImpregnationTestType, 'Name');

    return label;
  }

  private saveTest(test) {
    delete test.ImpregnationTestType;
    this.impregnationTestService
      .put(test, test.Id)
      .then((res) => {
        this.eventAggregator.publish('serviceImpregnationTestListReset', 0);
        this.toastService.showSuccess('service.impregnationTestSaved');
        this.testEditFormVisible = null;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private cancelEditTest() {
    this.testEditFormVisible = null;
  }

  private getOneToFourOptions() {
    return Promise.resolve(this.oneToFourOptions ? JSON.parse(JSON.stringify(this.oneToFourOptions)) : [])
  }
}
